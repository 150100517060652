var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', {
    staticClass: "h6 text-black fw-bold text-dark"
  }, [_vm._v("Redstone tokens")]), _vm.loading ? _c('Loader', {
    class: 'widget-loader',
    attrs: {
      "size": 40
    }
  }) : _c('div', [_c('div', {
    ref: "tabWrapper",
    staticClass: "token-tabs"
  }, [_c('div', {
    staticClass: "arrow",
    attrs: {
      "id": "leftArr"
    },
    on: {
      "click": function click($event) {
        return _vm.scrollLeft();
      }
    }
  }, [_c('i', {
    class: 'fi flaticon-arrow-left-active'
  })]), _c('div', {
    staticClass: "arrow",
    attrs: {
      "id": "rightArr"
    },
    on: {
      "click": function click($event) {
        return _vm.scrollRight();
      }
    }
  }, [_c('i', {
    class: 'fi flaticon-arrow-left-active'
  })]), _c('b-tabs', {
    ref: "tabScroll",
    staticClass: "showArrows",
    attrs: {
      "sm-pills": "",
      "md-tabs": "",
      "nav-class": "bg-transparent"
    },
    model: {
      value: _vm.selectedTabIndex,
      callback: function callback($$v) {
        _vm.selectedTabIndex = $$v;
      },
      expression: "selectedTabIndex"
    }
  }, _vm._l(_vm.tokenTypes, function (type) {
    return _c('b-tab', {
      key: type.label,
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_vm._v(" " + _vm._s(type.label) + " "), _c('span', {
            staticClass: "tokens-number"
          }, [_vm._v(" " + _vm._s(_vm.getFilteredTokensWithPrices(type.tag).length) + " ")])];
        },
        proxy: true
      }], null, true)
    }, [_c('TokenCards', {
      key: _vm.searchTerm + type.tag,
      attrs: {
        "tokens": _vm.getFilteredTokensWithPrices(type.tag)
      }
    })], 1);
  }), 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }