var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrapper"
  }, [_c('div', {
    staticClass: "chart-header"
  }, [_c('span', [_vm._v("Transactions chart")]), _c('div', {
    staticClass: "chart-controls"
  }, _vm._l(_vm.availableRanges, function (range) {
    return !_vm.duplicatedRanges.includes(range.value) ? _c('button', {
      key: range.value,
      staticClass: "range-button",
      class: {
        active: _vm.selectedRange === range.value
      },
      on: {
        "click": function click($event) {
          return _vm.onRangeChange(range.value);
        }
      }
    }, [_vm._v(" " + _vm._s(range.label) + " ")]) : _vm._e();
  }), 0)]), _c('div', {
    staticClass: "chart-container"
  }, [_c('apexchart', {
    ref: "chart",
    attrs: {
      "type": "area",
      "options": _vm.chartOptions,
      "series": _vm.series,
      "height": _vm.isScreen('sm') ? 250 : 450
    },
    on: {
      "zoomed": _vm.onZoomed,
      "beforeResetZoom": _vm.onBeforeReset
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }