<template>
  <div class="wrapper">
    <div class="chart-header">
      <span>Transactions chart</span>
      <div class="chart-controls">
        <button
          v-for="range in availableRanges"
          :key="range.value"
          class="range-button"
          :class="{ active: selectedRange === range.value }"
          @click="onRangeChange(range.value)"
          v-if="!duplicatedRanges.includes(range.value)"
        >
          {{ range.label }}
        </button>
      </div>
    </div>

    <div class="chart-container">
      <apexchart
        ref="chart"
        type="area"
        :options="chartOptions"
        :series="series"
        :height="isScreen('sm') ? 250 : 450"
        @zoomed="onZoomed"
        @beforeResetZoom="onBeforeReset"
      />
    </div>
  </div>
</template>

<script>
import { processDataForRange, parseTimestamp } from "./dataProcessor";
import config from "./chartConfig";
import isScreen from "@/core/screenHelper";
import {
  currencySymbolMap,
} from "../../utils/FeedsTableDataLayer";

export default {
  name: "FeedChart",
  props: {
    explorerUrl: {
      type: String,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    range: {
      type: String,
      required: true,
    },
    duplicatedRanges: {
      type: Array,
      required: true,
    },
    denomination: {
      type: String,
      required: true,
    },
    specialDenomination: {
      type: Boolean,
      default: false,
    },
    maxDataPoints: {
      type: Number,
      default: 250,
    },
  },

  data() {
    return {
      selectedRange: this.range,
      ranges: [
        { value: "1d", label: "1D" },
        { value: "1w", label: "1W" },
        { value: "1m", label: "1M" },
      ],
      processedData: [],
      originalData: [],
      hoveredPointIndex: null,
      zoomState: null,
    };
  },

  computed: {
    availableRanges() {
      return this.ranges;
    },

    formattedCurrency() {
      return currencySymbolMap[this.denomination] || this.denomination;
    },

    series() {
      return [
        {
          name: "Price",
          data: this.processedData,
        },
      ];
    },

    chartOptions() {
      const baseConfig = config(this);
      return {
        ...baseConfig,
        chart: {
          ...baseConfig.chart,
          events: {
            ...baseConfig.chart.events,
            beforeResetZoom: () => {
              this.onBeforeReset();
              return false;
            }
          }
        }
      };
    },

    visibleData() {
      if (!this.zoomState) return this.processedData;

      const { min, max } = this.zoomState;
      return this.processedData.filter(point => {
        const x = point.x;
        return x >= min && x <= max;
      });
    },
  },

  methods: {
    isScreen,
    getTimeFormat() {
      switch (this.selectedRange) {
        case "1d":
          return "HH:mm";
        case "1w":
          return "MMM dd HH:mm";
        case "1m":
          return "MMM dd";
        default:
          return "MMM dd";
      }
    },

    processChartData(data = this.data) {
      const sortedData = processDataForRange(data);
      const points = sortedData.map((point) => ({
        x: parseTimestamp(point.timestamp).getTime(),
        y: parseFloat(point.value),
        txHash: point.txHash,
      }));

      // Store original data for reset
      this.originalData = [...points];

      if (!this.zoomState && points.length > this.maxDataPoints) {
        return this.sampleData(points);
      }

      return points;
    },

    sampleData(data) {
      const totalPoints = data.length;
      const step = Math.floor(totalPoints / this.maxDataPoints);
      
      const sampledData = [];
      sampledData.push(data[0]);

      for (let i = step; i < totalPoints - step; i += step) {
        sampledData.push(data[i]);
      }

      sampledData.push(data[totalPoints - 1]);
      return sampledData;
    },

    onBeforeReset() {
      this.zoomState = null;
      this.processedData = this.processChartData();
      this.$nextTick(() => {
        if (this.$refs.chart) {
          this.$refs.chart.updateSeries([{
            name: 'Price',
            data: this.processedData
          }], false);
        }
      });
    },

    onZoomed(chartContext, { xaxis }) {
      this.zoomState = {
        min: xaxis.min,
        max: xaxis.max
      };
      
      const visibleDataCount = this.visibleData.length;
      
      if (visibleDataCount < this.maxDataPoints) {
        const filteredOriginalData = this.originalData.filter(point => 
          point.x >= xaxis.min && point.x <= xaxis.max
        );
        
        if (filteredOriginalData.length > 0) {
          this.$refs.chart.updateSeries([{
            name: 'Price',
            data: filteredOriginalData
          }], false);
          this.processedData = filteredOriginalData;
        }
      }
    },

    onRangeChange(range) {
      this.selectedRange = range;
      this.zoomState = null;
      this.$emit("range-change", range);
      this.processedData = this.processChartData();
      this.updateChart();
    },

    updateChart() {
      if (this.$refs.chart) {
        this.$refs.chart.updateOptions(this.chartOptions);
      }
    },
  },

  watch: {
    data: {
      handler() {
        this.zoomState = null;
        this.processedData = this.processChartData();
        this.$nextTick(() => {
          this.$emit("points-updated", this.processedData);
        });
      },
      immediate: true,
    },

    selectedIndex() {
      this.updateChart();
    },

    hoveredTimelineIndex() {
      this.updateChart();
    },

    denomination() {
      this.updateChart();
    },

    specialDenomination() {
      this.updateChart();
    },
  },

  mounted() {
    this.processedData = this.processChartData();
    this.$emit("points-updated", this.processedData);
  },
};
</script>

<style lang="scss" scoped>
.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #f1f1f1;

  span {
    font-weight: 600;
    color: #333333;
  }
}

.chart-controls {
  display: flex;
  gap: 0.5rem;
}

.range-button {
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  border: 1px solid #fd627a;
  background: transparent;
  color: #fd627a;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(253, 98, 122, 0.1);
  }

  &.active {
    background: #fd627a;
    color: white;
  }
}

.chart-container {
  flex: 1;
  min-height: 0;
  padding: 1rem;
}

::v-deep .apexcharts-selected:nth-child(3) {
  svg {
    fill: #fd627a !important;
  }
}
::v-deep .apexcharts-selected:nth-child(4) {
  svg {
    stroke: #fd627a !important;
  }
}
</style>