import { parseISO } from "date-fns";

export const parseTimestamp = (timestamp) => {
  if (typeof timestamp === "number") return new Date(timestamp);
  if (typeof timestamp === "string") return parseISO(timestamp);
  return new Date(NaN);
};
export const processDataForRange = (data) => {
  return data
    .filter(entry => {
      const timestamp = parseTimestamp(entry.timestamp);
      return timestamp.getTime() && !isNaN(parseFloat(entry.value));
    })
    .sort((a, b) => parseTimestamp(a.timestamp) - parseTimestamp(b.timestamp));
};