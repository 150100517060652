<template>
    <router-view />
</template>

<script>
  import { mapActions } from "vuex";
  import { mapState } from "vuex/dist/vuex.common.js";

  export default {
    name: "App",

    async beforeMount() {
      this.prefetchAll();
      await this.getOrderedProviders();
      await this.getAllSymbolDetails();
    },
    computed: {
      ...mapState("manifests", ["manifestsLoadComplete"]),
    },
    methods: {
      ...mapActions("manifests", ["getAllSymbolDetails", "getOrderedProviders"]),
      ...mapActions("prefetch", ["prefetchAll"]),
    },
  };
</script>

<style src="./styles/theme.scss" lang="scss" />
